import { TableRow } from "articon-component-library";
import { VehicleBlueprint } from "./VehicleBlueprint.types";

/**
 * Util to create table rows for vehicle blueprints
 * @param blueprints  - The blueprints to create table rows for
 * @param onClickRow  - The function to call when a row is clicked
 * @returns  {TableRow[]} - The table rows
 */
export const createTableRowsForVehicleBlueprints = (
  blueprints: VehicleBlueprint[],
  onClickRow: (blueprint: VehicleBlueprint) => void
): TableRow[] => {
  return blueprints.map((blueprint) =>
    createTableRowEntryForVehicleBlueprint(blueprint, onClickRow)
  );
};

/**
 *  Util to create a table row for a blueprint
 * @param blueprint - The blueprint to create a table row for
 * @param groups  - The blueprint groups to use for the table row
 * @param onClick  - The function to call when the table row is clicked
 * @returns  {TableRow} - The table row
 */
export const createTableRowEntryForVehicleBlueprint = (
  blueprint: VehicleBlueprint,
  onClick: (blueprint: VehicleBlueprint) => void
): TableRow => ({
  value: [
    {
      value: blueprint.name,
    },
    {
      value: blueprint.type.name,
    },
  ],
  onClick: () => onClick(blueprint),
  onClickMobile: () => onClick(blueprint),
});
