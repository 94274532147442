import { Address } from "../customer/Customer.types";

/**
 * Convert a address object to a concatenated string
 *
 * @param address The address object which should be converted
 * @returns The concatenated string of the address object
 */
export const convertAddressToConcatenatedString = (address: Address): string =>
  `${address.street} ${address.number}, ${address.zip} ${address.city}`;
