import { ButtonComponent, InputComponent } from "articon-component-library";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { logInWithEmailAndPassword } from "../utils/FirebaseUtils";

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [mail, setMail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();
  return (
    <form
      id="login-form"
      onSubmit={(evt) => {
        evt.preventDefault();
        logInWithEmailAndPassword(mail, password).then((success) => {
          if (success) navigate("/");
        });
      }}
    >
      <InputComponent
        label={t("pages.login.email")}
        value={mail}
        onChange={setMail}
        type="email"
      />
      <InputComponent
        label={t("pages.login.password")}
        value={password}
        onChange={setPassword}
        type="password"
      />
      <ButtonComponent
        value={t("buttons.login")}
        type="submit"
        form="login-form"
      />
    </form>
  );
};

export default Login;
