import { DropdownComponent, TableRow } from "articon-component-library";
import i18n from "../../i18n";
import { Customer } from "../customer/Customer.types";
import { getOptionsForCustomers } from "../customer/Customer.utils";
import { Gateway } from "./Gateway.types";
import dayjs from "dayjs";

/**
 * Util method to create table rows for gateways
 * @param gateways  The gateways to create table rows for
 * @param customers  The customers to create table rows for
 * @param onClickRow  The function to call when a row is clicked
 * @param onDeleteRow  The function to call when a row is deleted
 * @param updateSingleGateway  The function to call when a single gateway is updated
 * @returns  The table rows for the gateways
 */
export const createTableRowsForGateways = (
  gateways: Gateway[],
  customers: Customer[],
  onClickRow: (gateway: Gateway) => void,
  onDeleteRow: (gateway: Gateway) => void,
  updateSingleGateway: (gateway: Gateway) => void
): TableRow[] => {
  const gatewayEntries: TableRow[] = [];
  gateways.forEach((gateway) => {
    const gatewayRow: TableRow = createTableRowForGateway(
      gateway,
      customers,
      onClickRow,
      onDeleteRow,
      updateSingleGateway
    );
    gatewayEntries.push(gatewayRow);
  });
  return gatewayEntries;
};

/**
 * Util method to create a table row for a gateway
 * @param gateway  The gateway to create a table row for
 * @param customers  The customers to create a table row for
 * @param onClick  The function to call when a row is clicked
 * @param onDelete  The function to call when a row is deleted
 * @param updateSingleGateway  The function to call when a single gateway is updated
 * @returns  The table row for the gateway
 */
export const createTableRowForGateway = (
  gateway: Gateway,
  customers: Customer[],
  onClick: (gateway: Gateway) => void,
  onDelete: (gateway: Gateway) => void,
  updateSingleGateway: (gateway: Gateway) => void
): TableRow => {
  const gatewayRow: TableRow = {
    value: [
      { value: gateway.uid },
      { value: gateway.name },
      { value: i18n.t("pages.assetsAdministration.gateway.type") },
      {
        value: (
          <DropdownComponent
            options={getOptionsForCustomers(customers)}
            selectedOption={gateway.customerUid}
            onChange={(customerUid) => {
              updateSingleGateway({ ...gateway, customerUid });
            }}
          />
        ),
      },
      { value: dayjs(gateway.lastUpdated).fromNow() },
      { value: dayjs(gateway.lastSynced).fromNow() },
    ],
    onClick: () => onClick(gateway),
    onClickMobile: () => onClick(gateway),
  };
  return gatewayRow;
};
