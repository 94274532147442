import useSWR from "swr";
import { useAxios } from "../../hooks/useAxios";
import {
  getQualificationGroups,
  getQualifications,
} from "../../utils/qualifications/Qualification.axios";
import { useTranslation } from "react-i18next";
import { TableComponent, ButtonComponent } from "articon-component-library";
import { createTableRowsForQualifications } from "../../utils/qualifications/Qualification.utils";
import { useNavigate } from "react-router";

const QualificationAdministration: React.FC = () => {
  const { axios } = useAxios();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const qualifications = useSWR(
    axios ? "/qualifications" : null,
    () => (!!axios ? getQualifications(axios) : []),
    { fallbackData: [] }
  );

  const qualificationGroups = useSWR(
    axios ? "/qualification/groups" : null,
    () => (!!axios ? getQualificationGroups(axios) : []),
    { fallbackData: [] }
  );

  return (
    <>
      <h2>{t("pages.qualificationAdministration.heading")}</h2>

      <TableComponent
        tableHeader={t("pages.qualificationAdministration.tableHeader", {
          returnObjects: true,
        })}
        tableEntries={createTableRowsForQualifications(
          qualifications.data,
          qualificationGroups.data,
          (qualification) =>
            navigate("edit", {
              state: { selectedQualification: qualification },
            })
        )}
      />

      <ButtonComponent
        value={t("buttons.create")}
        onClick={() => navigate("create")}
      />
    </>
  );
};

export default QualificationAdministration;
