import axios, { AxiosInstance } from "axios";
import { useEffect, useMemo, useState } from "react";
import { getUserJwt } from "../utils/FirebaseUtils";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { handleDates } from "../utils/Axios.utils";

/**
 * Creates a preconfigured axios wrapper that has authorization for
 * backend service
 */
export const useAxios = (): {
  axios: AxiosInstance | undefined;
} => {
  const [userJwt, setUserJwt] = useState<string>("");
  const [authUser] = useAuthState(auth);

  //this useEffect makes sure we don't set jwt before auth user is available
  useEffect(() => {
    if (authUser) getUserJwt().then(setUserJwt);
    // eslint-disable-next-line
  }, [authUser]);

  return useMemo(() => {
    if (userJwt) {
      const instance = axios.create({
        headers: {
          Authorization: `Bearer ${userJwt}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      });
      instance.interceptors.response.use((originalResponse) => {
        handleDates(originalResponse.data);
        return originalResponse;
      });
      return {
        axios: instance,
      };
    } else return { axios: undefined };
  }, [userJwt]);
};
