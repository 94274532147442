import {
  ButtonComponent,
  TableComponent,
  TableRow,
} from "articon-component-library";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateOrUpdateCustomer from "../components/CreateOrUpdateCustomer";
import { useAxios } from "../hooks/useAxios";
import {
  deleteCustomer,
  loadAllCustomers,
} from "../utils/customer/Customer.axios";
import {
  Customer,
  generateEmptyCustomer,
} from "../utils/customer/Customer.types";
import {
  createTableRowsForCustomers,
  updateCustomerInArray,
} from "../utils/customer/Customer.utils";

const CustomerAdministration: React.FC = () => {
  const [customerEntries, setCustomerEntries] = useState<TableRow[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [currentCustomer, setCurrentCustomer] = useState<Customer>();
  const { t } = useTranslation();
  const { axios } = useAxios();

  //loads customers once axios is available
  useEffect(() => {
    if (axios)
      loadAllCustomers(axios).then((customers) => setCustomers(customers));
  }, [axios]);

  //sets customers as table entries
  useEffect(() => {
    setCustomerEntries(
      createTableRowsForCustomers(
        customers,
        setCurrentCustomer,
        onDeleteCustomer
      )
    );
    // eslint-disable-next-line
  }, [customers]);

  /**
   * deletes customer on backend and updates local customer array
   * @param customer
   */
  const onDeleteCustomer = (customer: Customer): void => {
    if (axios)
      deleteCustomer(axios, customer.uid).then(() =>
        setCustomers(updateCustomerInArray(customers, customer, true))
      );
  };

  return (
    <>
      {!!currentCustomer ? (
        <CreateOrUpdateCustomer
          customerToEdit={currentCustomer}
          updateCustomerLocal={(customer) => {
            setCustomers(updateCustomerInArray(customers, customer));
            setCurrentCustomer(undefined);
          }}
        />
      ) : (
        <div>
          <TableComponent
            tableHeader={t("pages.customerAdministration.tableHeader", {
              returnObjects: true,
            })}
            tableEntries={customerEntries}
          />
        </div>
      )}
      <ButtonComponent
        value={t(`buttons.${!!currentCustomer ? "back" : "create"}`)}
        onClick={() => {
          if (currentCustomer) setCurrentCustomer(undefined);
          else setCurrentCustomer(generateEmptyCustomer());
        }}
      />
    </>
  );
};

export default CustomerAdministration;
