import { ButtonComponent, InputComponent } from "articon-component-library";
import { useCallback, useState } from "react";
import {
  Address,
  generateEmptyAddress,
} from "../utils/customer/Customer.types";
import { useTranslation } from "react-i18next";
import { useAxios } from "../hooks/useAxios";
import { getGeocoding } from "../utils/geocoding/Geocoding.axios";
import { Geocoding } from "../utils/geocoding/Geocoding.types";
import { convertAddressToConcatenatedString } from "../utils/geocoding/Geocoding.utils";

type LatLngTuple = [lat: number, lng: number];

interface AddressFormProps {
  initAddress?: Address;
  initCoordinates?: LatLngTuple;
  onChange?: (address: Address, coordinates: LatLngTuple) => void;
}

export const AddressForm: React.FC<AddressFormProps> = ({
  initAddress,
  initCoordinates,
  onChange,
}) => {
  const { axios } = useAxios();
  const { t } = useTranslation();
  const [address, setAddress] = useState<Address>(
    initAddress ?? generateEmptyAddress()
  );
  const [coordinates, setCoordinates] = useState<LatLngTuple>(
    initCoordinates ?? [0, 0]
  );

  /**
   * Handler for updating the address and trigger the onChange callback
   */
  const updateAddress = useCallback(
    (update: Partial<Address>) => {
      setAddress((current) => {
        const newAddress = { ...current, ...update };
        onChange?.(newAddress, coordinates);
        return newAddress;
      });
    },
    [onChange, coordinates]
  );

  /**
   * Handler for updating the address and trigger the onChange callback
   */
  const updateCoordinates = useCallback(
    (update: Partial<{ lat: number; lng: number }>) => {
      setCoordinates((current) => {
        const newCoordinates: LatLngTuple = [
          update.lat ?? current[0],
          update.lng ?? current[1],
        ];
        onChange?.(address, newCoordinates);
        return newCoordinates;
      });
    },
    [onChange, address]
  );

  /**
   * Loads the coordinates for the current address
   */
  const loadCoordinates = useCallback(async () => {
    if (!axios) return;
    const newLatLng: Geocoding = await getGeocoding(
      axios,
      convertAddressToConcatenatedString(address)
    );
    updateCoordinates({ lat: newLatLng.lat, lng: newLatLng.lon });
  }, [axios, address, updateCoordinates]);

  return (
    <>
      <InputComponent
        type="text"
        required
        label={t("components.addressForm.street")}
        value={address.street}
        onChange={(street) => updateAddress({ street })}
      />
      <InputComponent
        type="text"
        required
        label={t("components.addressForm.number")}
        value={address.number}
        onChange={(number) => updateAddress({ number })}
      />
      <InputComponent
        type="text"
        label={t("components.addressForm.additionalInfo")}
        value={address.additionalInfo}
        onChange={(additionalInfo) => updateAddress({ additionalInfo })}
      />
      <InputComponent
        type="text"
        required
        label={t("components.addressForm.zip")}
        value={address.zip}
        onChange={(zip) => updateAddress({ zip })}
      />
      <InputComponent
        type="text"
        required
        label={t("components.addressForm.city")}
        value={address.city}
        onChange={(city) => updateAddress({ city })}
      />
      <div className="address-form-component__coordinates">
        <InputComponent
          type="number"
          required
          label={t("components.addressForm.lat")}
          value={coordinates[0]}
          onChangeNumber={(lat) => updateCoordinates({ lat })}
        />
        <InputComponent
          type="number"
          required
          label={t("components.addressForm.lng")}
          value={coordinates[1]}
          onChangeNumber={(lng) => updateCoordinates({ lng })}
        />
        <ButtonComponent
          value={t("components.addressForm.loadCoordinates")}
          onClick={loadCoordinates}
        />
      </div>
    </>
  );
};
