import { MenuGroup } from "articon-component-library";
import { ReactComponent as CustomerSettingsIcon } from "../assets/menu/customer-settings.svg";
import { ReactComponent as UserSettingsIcon } from "../assets/menu/user-settings.svg";
import { ReactComponent as BeaconIcon } from "../assets/menu/sensors.svg";
import { ReactComponent as HydrantIcon } from "../assets/menu/Hydrant.svg";
import { ReactComponent as AirportShuttleIcon } from "../assets/menu/airport_shuttle.svg";
import { ReactComponent as BadgeIcon } from "../assets/menu/badge.svg";
import { ReactComponent as DatasetIcon } from "../assets/menu/dataset.svg";
import { v4 as uuid } from "uuid";

import i18n from "../i18n";

/**
 * Helper to build menu items for layout
 *
 * @returns menuItems for layout
 * @tested
 */
export const buildMenu = (): MenuGroup[] => [
  {
    id: uuid(),
    title: i18n.t("menu.title"),
    items: [
      {
        id: uuid(),
        title: i18n.t("menu.userAdmin"),
        link: "/user",
        icon: <UserSettingsIcon />,
      },
      {
        id: uuid(),
        title: i18n.t("menu.customerAdmin"),
        link: "/customer",
        icon: <CustomerSettingsIcon />,
      },
      {
        id: uuid(),
        title: i18n.t("menu.stagingRoomAdmin"),
        link: "/staging",
        icon: <CustomerSettingsIcon />,
      },
      {
        id: uuid(),
        title: i18n.t("menu.hydrantAdmin"),
        link: "/hydrant",
        icon: <HydrantIcon />,
      },
      {
        id: uuid(),
        title: i18n.t("menu.assetsAdmin"),
        link: "/assets",
        icon: <BeaconIcon />,
      },
      {
        id: uuid(),
        title: i18n.t("menu.materialAdmin"),
        link: "/material",
        icon: <DatasetIcon />,
      },
      {
        id: uuid(),
        title: i18n.t("menu.qualificationAdmin"),
        link: "/qualification",
        icon: <BadgeIcon />,
      },
      {
        id: uuid(),
        title: i18n.t("menu.vehicleBlueprintAdmin"),
        link: "/vehicle/blueprint",
        icon: <AirportShuttleIcon />,
      },
    ],
  },
];
