import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { userServiceBaseUrl } from "../user/User.axios";
import { Beacon } from "./Beacon.types";

/**
 * API method to update a single {@link Beacon}
 * @param axios  axios instance
 * @param beacon  beacon to update
 * @returns  true if update was successful, false otherwise
 */
export const updateBeacon = async (
  axios: AxiosInstance,
  beacon: Beacon
): Promise<boolean> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve(false);
  return axios
    .post(`${userServiceBaseUrl}/beacon/update/`, beacon)
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Beacon update couldn't be performed", err);
      return false;
    });
};

/**
 *  API method to load all {@link Beacon}s
 * @param axios  axios instance
 * @returns  The list of all beacons, can be empty
 */
export const getAllBeacons = async (
  axios: AxiosInstance
): Promise<Beacon[]> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${userServiceBaseUrl}/beacon/all/`)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Beacons couldn't be loaded", err);
      return [];
    });
};

/**
 * API method to sync all {@link Beacon}s manually
 * @param axios  axios instance
 * @returns  true if sync was successful, false otherwise
 */
export const manuallySyncBeacons = async (
  axios: AxiosInstance
): Promise<Beacon[] | null> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve(null);
  return axios
    .post(`${userServiceBaseUrl}/beacon/sync/`)
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error();
    })
    .catch((err) => {
      console.error("Beacons couldn't be synced", err);
      return null;
    });
};
