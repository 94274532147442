import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { missionServiceBaseUrl } from "../user/User.axios";
import { Gateway } from "./Gateway.types";

/**
 * API method to update a single {@link Gateway}
 * @param axios axios instance
 * @param gateway gateway to update
 * @returns true if update was successful, false otherwise
 */
export const updateGateway = async (
  axios: AxiosInstance,
  gateway: Gateway
): Promise<boolean> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve(false);
  return axios
    .post(`${missionServiceBaseUrl}/gateway/update/`, gateway)
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Gateways update couldn't be performed", err);
      return false;
    });
};

/**
 *  API method to load all {@link Gateway}s
 * @param axios axios instance
 * @returns The list of all gateways, can be empty
 */
export const getAllGateways = async (
  axios: AxiosInstance
): Promise<Gateway[]> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${missionServiceBaseUrl}/gateway/all/`)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Gateways couldn't be loaded", err);
      return [];
    });
};

/**
 * API method to sync all {@link Gateway}s manually
 * @param axios axios instance
 * @returns true if sync was successful, false otherwise
 */
export const manuallySyncGateways = async (
  axios: AxiosInstance
): Promise<Gateway[] | null> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl)) return Promise.resolve(null);
  return axios
    .post(`${missionServiceBaseUrl}/gateway/sync/`)
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error();
    })
    .catch((err) => {
      console.error("Gateways couldn't be synced", err);
      return null;
    });
};
