/**
 * Helper to check if given value is an iso date string
 *
 * @param value any form of value to check
 * @returns true if value is iso date string
 * @tested
 */
export const isIsoDateString = (value: any): boolean => {
  //regex to check for iso date YYYY-MM-DDThh:mm:ss.mmm+hh:mm from firebase
  const isoDateFormat =
    /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
  return value && typeof value === "string" && isoDateFormat.test(value);
};

/**
 * Helper to find all iso dates in an object after receiving it from request
 * and parsing them
 *
 * @param body request body to check for dates and parse them
 * @tested
 */
export const handleDates = (body: any): void => {
  if (body === null || body === undefined || typeof body !== "object")
    return body;
  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = new Date(value);
    else if (typeof value === "object") handleDates(value);
  }
};

/**
 * Helper to check if baseUrl for axios call is available
 *
 * @param url url to check
 * @returns true if baseUrl is invalid, false otherwise
 * @tested
 */
export const isBaseUrlInvalid = (url: string): boolean => {
  if (url) return false;
  console.error("BaseUrl for service not available");
  return true;
};
