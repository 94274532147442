import { LoaderComponent } from "articon-component-library";
import { useMemo } from "react";
import { useLocation } from "react-router";
import CreateOrUpdateQualification from "../../components/CreateOrUpdateQualification";
import { Qualification } from "../../utils/qualifications/Qualification.types";

const QualificationEdit: React.FC = () => {
  const locationState = useLocation();

  const selectedQualification: Qualification | undefined = useMemo(
    () => locationState.state?.selectedQualification,
    [locationState]
  );
  return selectedQualification ? (
    <CreateOrUpdateQualification qualificationToEdit={selectedQualification} />
  ) : (
    <LoaderComponent />
  );
};

export default QualificationEdit;
