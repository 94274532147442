import { Option, TableRow } from "articon-component-library";
import { Customer } from "./Customer.types";

/**
 * Helper to build Options for Customers
 * @param customers
 * @returns customer Options
 * @tested
 */
export const getOptionsForCustomers = (customers: Customer[]): Option[] =>
  customers.map((customer) => ({
    label: customer.name,
    value: customer.uid,
  }));

/**
 * Helper to create TableRows from Customer objects
 *
 * @param customers customers to get TableRows for
 * @param onClickRow onClick function for row
 * @param onDeleteRow onDelete function for row
 * @returns TableRows for Customers
 * @tested
 */
export const createTableRowsForCustomers = (
  customers: Customer[],
  onClickRow: (customer: Customer) => void,
  onDeleteRow: (customer: Customer) => void
): TableRow[] => {
  const userEntries: TableRow[] = [];
  for (const customer of customers) {
    const customerRow: TableRow = createTableRowForCustomer(
      customer,
      onClickRow,
      onDeleteRow
    );
    userEntries.push(customerRow);
  }
  return userEntries;
};

/**
 * Helper to create TableRow from Customer object
 *
 * @param customer customer to get TableRow for
 * @param onClick onClick for row
 * @param onDelete delete function for row
 * @returns TableRow for Customer
 * @tested
 */
export const createTableRowForCustomer = (
  customer: Customer,
  onClick: (customer: Customer) => void,
  onDelete: (customer: Customer) => void
): TableRow => {
  const customerRow: TableRow = {
    value: [
      { value: customer.name },
      { value: customer.address.city },
      { value: customer.userAmount.toFixed() },
      {
        value: (
          <div
            onClick={(evt) => {
              evt.stopPropagation();
              onDelete(customer);
            }}
          >
            X
          </div>
        ),
      },
    ],
    onClick: () => onClick(customer),
    onClickMobile: () => onClick(customer),
  };
  return customerRow;
};

/**
 * Helper method to update customers array after change
 *
 * @param customers customers array to update
 * @param customer customer to create/update/delete in customers array
 * @param isDeletion wheter or not the performed action is a deletion
 * @returns updated version of given array
 * @tested
 */
export const updateCustomerInArray = (
  customers: Customer[],
  customer: Customer,
  isDeletion?: boolean
): Customer[] => {
  const customerIndex: number = customers.findIndex(
    (customerToCompare) => customer.uid === customerToCompare.uid
  );
  const updatedArray: Customer[] = [...customers];
  if (isDeletion && customerIndex !== -1) updatedArray.splice(customerIndex, 1);
  else {
    if (customerIndex === -1) updatedArray.push(customer);
    else updatedArray[customerIndex] = customer;
  }
  return updatedArray;
};
