import {
  ButtonComponent,
  TableComponent,
  TableRow,
} from "articon-component-library";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateOrUpdateUser from "../components/CreateOrUpdateUser";
import { useAxios } from "../hooks/useAxios";
import { deleteUser, loadAllUsers } from "../utils/user/AdminUser.utils";
import { generateEmptyUser, User } from "../utils/user/User.types";
import {
  createTableRowsForUsers,
  updateUserInArray,
} from "../utils/user/User.utils";

const UserAdministration: React.FC = () => {
  const [userEntries, setUserEntries] = useState<TableRow[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [currentUser, setCurrentUser] = useState<User>();
  const { t } = useTranslation();
  const { axios } = useAxios();

  //loads users once axios is available
  useEffect(() => {
    if (axios) loadAllUsers(axios).then((users) => setUsers(users));
  }, [axios]);

  //sets users as table entries
  useEffect(() => {
    setUserEntries(
      createTableRowsForUsers(users, setCurrentUser, onDeleteUser)
    );
    // eslint-disable-next-line
  }, [users]);

  /**
   * deletes user on backend and updates local user array
   * @param user
   */
  const onDeleteUser = (user: User): void => {
    if (axios)
      deleteUser(axios, user.uid).then(() =>
        setUsers(updateUserInArray(users, user, true))
      );
  };

  return (
    <>
      {!!currentUser ? (
        <CreateOrUpdateUser
          userToEdit={currentUser}
          updateUserLocal={(user) => {
            setUsers(updateUserInArray(users, user));
            setCurrentUser(undefined);
          }}
        />
      ) : (
        <TableComponent
          tableHeader={t("pages.userAdministration.tableHeader", {
            returnObjects: true,
          })}
          tableEntries={userEntries}
        />
      )}
      <ButtonComponent
        value={t(`buttons.${!!currentUser ? "back" : "create"}`)}
        onClick={() => {
          if (currentUser) setCurrentUser(undefined);
          else setCurrentUser(generateEmptyUser());
        }}
      />
    </>
  );
};

export default UserAdministration;
