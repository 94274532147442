import {
  ButtonComponent,
  TableComponent,
  TableRow,
} from "articon-component-library";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAxios } from "../hooks/useAxios";
import {
  getAllBeacons,
  manuallySyncBeacons,
  updateBeacon,
} from "../utils/beacon/Beacon.axios";
import { Beacon } from "../utils/beacon/Beacon.types";
import { createTableRowsForBeacons } from "../utils/beacon/Beacon.utils";
import { loadAllCustomers } from "../utils/customer/Customer.axios";
import useSWR from "swr";
import { getAllGateways, updateGateway } from "../utils/gateway/Gateway.axios";
import { manuallySyncGateways } from "../utils/gateway/Gateway.axios";
import { createTableRowsForGateways } from "../utils/gateway/Gateway.utils";
import { Gateway } from "../utils/gateway/Gateway.types";

const AssetAdministration: React.FC = () => {
  return (
    <div>
      <BeaconAdministration />
      <hr />
      <GatewayAdministration />
    </div>
  );
};

const BeaconAdministration: React.FC = () => {
  const { t } = useTranslation();
  const { axios } = useAxios();
  const beacons = useSWR(
    ["user/beacon/all", axios],
    ([, axios]) => (!!axios ? getAllBeacons(axios) : []),
    { fallbackData: [] }
  );
  const customers = useSWR(
    ["user/customer/all", axios],
    ([, axios]) => (!!axios ? loadAllCustomers(axios) : []),
    { fallbackData: [] }
  );

  /**
   * Update a single beacon in the beacons array
   * @param beacon  The beacon to update
   */
  const updateSingleBeacon = useCallback(
    (beaconToUpdate: Beacon): void => {
      if (!axios) return;
      updateBeacon(axios, beaconToUpdate).then((success) => {
        if (success) {
          beacons.mutate(
            beacons.data.map((beacon) =>
              beacon.uid === beaconToUpdate.uid ? beaconToUpdate : beacon
            )
          );
        }
      });
    },
    [axios, beacons]
  );

  /**
   * Creates table rows for beacons
   */
  const beaconEntries: TableRow[] = useMemo((): TableRow[] => {
    if (!axios) return [];
    return createTableRowsForBeacons(
      beacons.data,
      customers.data,
      () => {},
      () => {},
      updateSingleBeacon
    );
  }, [axios, beacons, customers, updateSingleBeacon]);

  /**
   * Handles the click on the manual sync button
   * @returns void
   */
  const handleManualSyncClick = useCallback(async (): Promise<void> => {
    if (!axios) return;
    const updatedBeacons = await manuallySyncBeacons(axios);
    beacons.mutate(updatedBeacons ?? undefined);
  }, [axios, beacons]);

  return (
    <div className="beacon-page__wrapper">
      <h2>{t("pages.assetsAdministration.beacon.heading")}</h2>

      <div className="beacon-page__content">
        <ButtonComponent
          onClick={handleManualSyncClick}
          value={t("pages.assetsAdministration.beacon.syncManually")}
        />
        <TableComponent
          tableHeader={t("pages.assetsAdministration.beacon.tableHeader", {
            returnObjects: true,
          })}
          tableEntries={beaconEntries}
        />
      </div>
    </div>
  );
};

const GatewayAdministration: React.FC = () => {
  const { t } = useTranslation();
  const { axios } = useAxios();
  const gateways = useSWR(
    ["misson/gateway/all", axios],
    ([, axios]) => (!!axios ? getAllGateways(axios) : []),
    { fallbackData: [] }
  );
  const customers = useSWR(
    ["user/customer/all", axios],
    ([, axios]) => (!!axios ? loadAllCustomers(axios) : []),
    { fallbackData: [] }
  );

  /**
   * Update a single gateway in the gateways array
   * @param gateway  The gateway to update
   */
  const updateSingleGateway = useCallback(
    (gatewayToUpdate: Gateway): void => {
      if (!axios) return;
      updateGateway(axios, gatewayToUpdate).then((success) => {
        if (success) {
          gateways.mutate(
            gateways.data.map((gateway) =>
              gateway.uid === gatewayToUpdate.uid ? gatewayToUpdate : gateway
            )
          );
        }
      });
    },
    [axios, gateways]
  );

  /**
   * Creates table rows for gateways
   */
  const gatewayEntries: TableRow[] = useMemo((): TableRow[] => {
    if (!axios) return [];
    return createTableRowsForGateways(
      gateways.data,
      customers.data,
      () => {},
      () => {},
      updateSingleGateway
    );
  }, [axios, gateways, customers, updateSingleGateway]);

  /**
   * Handles the click on the manual sync button
   */
  const handleManualSyncClick = useCallback(async (): Promise<void> => {
    if (!axios) return;
    const updatedGateways = await manuallySyncGateways(axios);
    await gateways.mutate(updatedGateways ?? undefined);
  }, [axios, gateways]);

  return (
    <div className="gateway-page__wrapper">
      <h2>{t("pages.assetsAdministration.gateway.heading")}</h2>

      <div className="gateway-page__content">
        <ButtonComponent
          onClick={handleManualSyncClick}
          value={t("pages.assetsAdministration.gateway.syncManually")}
        />
        <TableComponent
          tableHeader={t("pages.assetsAdministration.gateway.tableHeader", {
            returnObjects: true,
          })}
          tableEntries={gatewayEntries}
        />
      </div>
    </div>
  );
};

export default AssetAdministration;
