/**
 * Descrjbes a qualification a user can have
 */
export type Qualification = {
  uid: string;
  name: string;
  groupId?: string;
  displayType: QualificationDisplayType;
};

export enum QualificationDisplayType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

/**
 * Describes a qualification group, a qualification can be part of
 */
export interface QualificationGroup {
  uid: string;
  name: string;
  allowedAmount: number;
}

/**
 * Generates an empty qualification set
 * @returns {Qualification} empty qualification set
 */
export const generateEmptyQualification = (): Qualification => ({
  uid: undefined!,
  name: "",
  groupId: "",
  displayType: QualificationDisplayType.PRIMARY,
});

/**
 * Generates an empty qualification group
 * @returns {@QualificationGroup} empty qualification group
 */
export const generateEmptyQualificationGroup = (): QualificationGroup => ({
  uid: undefined!,
  name: "",
  allowedAmount: 0,
});
