import { AxiosInstance } from "axios";
import { Material } from "./Material.types";
import { missionServiceBaseUrl } from "../user/User.axios";

/**
 * Get all materials
 *
 * @param axios The axios instance
 * @returns The list of all materials, can be empty
 */
export const getAllMaterials = async (
  axios: AxiosInstance
): Promise<Material[]> =>
  axios
    .get(`${missionServiceBaseUrl}/material/all/`)
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error("Received unexpected status code: " + res.status);
    })
    .catch((err) => {
      console.error("Materials couldn't be loaded", err);
      return Promise.reject(err);
    });

/**
 * Get a single material by a given uid
 *
 * @param axios The axios instance
 * @param uid The uid of the material
 * @returns The material or null if it doesn't exist
 */
export const getMaterial = async (
  axios: AxiosInstance,
  uid: string
): Promise<Material | null> =>
  axios
    .get(`${missionServiceBaseUrl}/material/`, { params: { uid: uid } })
    .then((res) => {
      if (res.status === 200) return res.data;
      if (res.status === 412) return null;
      throw new Error("Received unexpected status code: " + res.status);
    })
    .catch((err) => {
      console.error("Material couldn't be loaded", err);
      return Promise.reject(err);
    });

/**
 * Update a given material
 *
 * @param axios The axios instance
 * @param material The material to update
 * @returns The updated material
 */
export const updateMaterial = async (
  axios: AxiosInstance,
  material: Material
): Promise<Material> =>
  axios
    .post(`${missionServiceBaseUrl}/material/update/`, material)
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error("Received unexpected status code: " + res.status);
    })
    .catch((err) => {
      console.error("Material couldn't be updated", err);
      return Promise.reject(err);
    });

/**
 * Create a new material
 *
 * @param axios The axios instance
 * @param material The material to create (currently only name is used)
 * @returns The newly created material
 */
export const createMaterial = async (
  axios: AxiosInstance,
  material: Material
): Promise<Material> =>
  axios
    .post(`${missionServiceBaseUrl}/material/`, material)
    .then((res) => {
      if (res.status === 201) return res.data;
      throw new Error("Received unexpected status code: " + res.status);
    })
    .catch((err) => {
      console.error("Material couldn't be created", err);
      return Promise.reject(err);
    });

/**
 * Delete a material by a given uid
 *
 * @param axios The axios instance
 * @param uid The uid of the material to delete
 * @returns void
 */
export const deleteMaterial = async (
  axios: AxiosInstance,
  uid: string
): Promise<void> =>
  axios
    .post(`${missionServiceBaseUrl}/material/delete/`, undefined, {
      params: { uid },
    })
    .then((res) => {
      if (res.status === 200) return;
      throw new Error("Received unexpected status code: " + res.status);
    })
    .catch((err) => {
      console.error("Material couldn't be deleted", err);
      return Promise.reject(err);
    });
