import { ButtonComponent, InputComponent } from "articon-component-library";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAxios } from "../hooks/useAxios";
import {
  createNewCustomer,
  updateCustomer,
} from "../utils/customer/Customer.axios";
import {
  Address,
  Customer,
  generateEmptyCustomer,
} from "../utils/customer/Customer.types";
import {} from "../utils/customer/Customer.utils";

interface CreateOrUpdateCustomerProps {
  customerToEdit?: Customer;
  updateCustomerLocal?(customer: Customer): void;
}

const CreateOrUpdateCustomer: React.FC<CreateOrUpdateCustomerProps> = ({
  customerToEdit,
  updateCustomerLocal,
}) => {
  const { t } = useTranslation();
  const [customer, setCustomer] = useState<Customer>(
    customerToEdit || generateEmptyCustomer()
  );
  const { axios } = useAxios();

  /**
   * Helper to update address of customer state
   * @param updatedAddress
   */
  const setCustomerAddress = (updatedAddress: Address): void => {
    setCustomer((customer) => ({
      ...customer,
      address: updatedAddress,
    }));
  };

  /**
   * Helper to submit customer after creation/change, will update when customer has uid and create
   * a new one otherwise
   */
  const handleSubmit = (): void => {
    if (!axios) {
      console.error(
        "Axios is undefined, therefore creation can't be performed"
      );
      return;
    }
    if (customer.uid)
      updateCustomer(axios, customer).then(() =>
        updateCustomerLocal?.(customer)
      );
    else
      createNewCustomer(axios, customer).then((uid) =>
        updateCustomerLocal?.({ ...customer, uid: uid })
      );
  };

  return (
    <form
      id="create-customer"
      onSubmit={(evt) => {
        evt.preventDefault();
        handleSubmit();
      }}
    >
      <InputComponent
        required
        label={t("components.createCustomer.name")}
        value={customer.name}
        onChange={(value) =>
          setCustomer((customer) => ({ ...customer, name: value }))
        }
      />
      <InputComponent
        required
        label={t("components.createCustomer.kennung")}
        value={customer.kennung}
        onChange={(kennung) =>
          setCustomer((customer) => ({ ...customer, kennung }))
        }
      />
      <InputComponent
        label={t("components.createCustomer.street")}
        value={customer.address.street}
        onChange={(value) =>
          setCustomerAddress({ ...customer.address, street: value })
        }
      />
      <InputComponent
        label={t("components.createCustomer.number")}
        value={customer.address.number}
        onChange={(value) =>
          setCustomerAddress({ ...customer.address, number: value })
        }
      />
      <InputComponent
        label={t("components.createCustomer.additionalInfo")}
        value={customer.address.additionalInfo}
        onChange={(value) =>
          setCustomerAddress({ ...customer.address, additionalInfo: value })
        }
      />
      <InputComponent
        label={t("components.createCustomer.zip")}
        value={customer.address.zip}
        onChange={(value) =>
          setCustomerAddress({ ...customer.address, zip: value })
        }
      />
      <InputComponent
        label={t("components.createCustomer.city")}
        value={customer.address.city}
        onChange={(value) =>
          setCustomerAddress({ ...customer.address, city: value })
        }
      />
      <ButtonComponent
        value={t(`buttons.${customer.uid ? "save" : "create"}`)}
        form="create-customer"
        type="submit"
      />
    </form>
  );
};
export default CreateOrUpdateCustomer;
