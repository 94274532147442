import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../components/PrivateLayoutWrapper";

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  return <div>{t("pages.dashboard.greeting", { user: user?.firstname })}</div>;
};

export default Dashboard;
