import { useNavigate } from "react-router-dom";
import CreateOrUpdateHydrant from "../../components/CreateOrUpdateHydrant";

const HydrantCreate: React.FC = () => {
  const navigate = useNavigate();

  return <CreateOrUpdateHydrant updateHydrantLocal={() => navigate(-1)} />;
};

export default HydrantCreate;
