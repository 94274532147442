import { LayoutComponent } from "articon-component-library";
import React from "react";
import { Outlet } from "react-router-dom";

const LayoutWrapper: React.FC = () => (
  <LayoutComponent>
    <Outlet />
  </LayoutComponent>
);
export default LayoutWrapper;
