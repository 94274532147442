import { Material } from "../material/Material.types";

/**
 * Type of the vehicle
 */
export interface VehicleType {
  uid: string;
  name: string;
  createDate: Date;
  lastUpdate: Date;
}
/**
 * Describes a vehicle blueprint
 */
export interface VehicleBlueprint {
  uid: string;
  createDate: Date;
  lastUpdate: Date;
  name: string;
  seats: number;
  type: VehicleType;
  standardLoad: StandardLoad[];
}

/**
 * A vehicle blueprint configures a standard load for a vehicle
 */
export interface StandardLoad {
  material: Material;
  amount: number;
}

/**
 * Util to create a vehicle blueprint
 * @returns  {@VehicleBlueprint} empty vehicle blueprint
 */
export const generateEmptyVehicleBlueprint = (): VehicleBlueprint => ({
  uid: undefined!,
  createDate: new Date(),
  lastUpdate: new Date(),
  name: "",
  seats: 0,
  type: generateEmptyVehicleType(),
  standardLoad: [],
});

/**
 * Util to create a vehicle type
 * @returns  {@VehicleType} empty vehicle type
 */
export const generateEmptyVehicleType = (): VehicleType => ({
  uid: undefined!,
  name: "",
  createDate: new Date(),
  lastUpdate: new Date(),
});
