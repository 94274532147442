import {
  TableRow,
  TableComponent,
  ButtonComponent,
} from "articon-component-library";
import useSWR from "swr";
import { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import CreateOrUpdateStagingRoom from "../components/CreateOrUpdateStagingRoom";
import { useAxios } from "../hooks/useAxios";
import {
  deleteStagingRoom,
  getAllStagingRooms,
  updateStagingRoom,
} from "../utils/staging/Staging.axios";
import {
  generateEmptyStagingRoom,
  StagingRoom,
} from "../utils/staging/Staging.types";
import { createTableRowsForStagingRooms } from "../utils/staging/Staging.utils";
import { loadAllCustomers } from "../utils/customer/Customer.axios";

const StagingRoomAdministration: React.FC = () => {
  const [currentStagingRoom, setCurrentStagingRoom] = useState<StagingRoom>();
  const { t } = useTranslation();
  const { axios } = useAxios();

  const stagingRooms = useSWR(
    axios ? "staging/all" : null,
    () => (axios ? getAllStagingRooms(axios) : []),
    { fallbackData: [] }
  );

  const customers = useSWR(
    axios ? "user/customer/all" : null,
    () => (axios ? loadAllCustomers(axios) : []),
    { fallbackData: [] }
  );

  /**
   * Updates the staging room on the backend and updates the local staging room array
   * @param updatedRoom  updated staging room
   */
  const updateSingleStagingRoom = useCallback(
    async (updatedRoom: StagingRoom) => {
      if (!axios) return;
      const success = await updateStagingRoom(axios, updatedRoom);
      if (!success) return;
      stagingRooms.mutate(
        stagingRooms.data.map((room) =>
          room.uid === updatedRoom.uid ? updatedRoom : room
        )
      );
    },
    [axios, stagingRooms]
  );

  /**
   * Deletes the staging room on the backend and updates the local staging room array
   * @param roomUid  uid of the staging room to be deleted
   */
  const deleteSingleStagingRoom = useCallback(
    async (roomUid: string) => {
      if (!axios) return;
      const success = await deleteStagingRoom(axios, roomUid);
      if (!success) return;
      stagingRooms.mutate(
        stagingRooms.data.filter((room) => room.uid !== roomUid)
      );
    },
    [axios, stagingRooms]
  );

  const stagingRoomEntries: TableRow[] = useMemo(
    () =>
      createTableRowsForStagingRooms(
        stagingRooms.data,
        customers.data,
        setCurrentStagingRoom,
        deleteSingleStagingRoom,
        updateSingleStagingRoom
      ),
    [
      stagingRooms,
      customers,
      deleteSingleStagingRoom,
      setCurrentStagingRoom,
      updateSingleStagingRoom,
    ]
  );

  return (
    <>
      {!!currentStagingRoom ? (
        <CreateOrUpdateStagingRoom stagingRoomToEdit={currentStagingRoom} />
      ) : (
        <TableComponent
          tableHeader={t("pages.stagingRoomAdministration.tableHeader", {
            returnObjects: true,
          })}
          tableEntries={stagingRoomEntries}
        />
      )}
      <ButtonComponent
        value={t(`buttons.${!!currentStagingRoom ? "back" : "create"}`)}
        onClick={() => {
          if (currentStagingRoom) setCurrentStagingRoom(undefined);
          else setCurrentStagingRoom(generateEmptyStagingRoom());
        }}
      />
    </>
  );
};

export default StagingRoomAdministration;
