import {
  ButtonComponent,
  DropdownComponent,
  InputComponent,
  Option,
} from "articon-component-library";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAxios } from "../hooks/useAxios";
import { loadAllCustomers } from "../utils/customer/Customer.axios";
import { getOptionsForCustomers } from "../utils/customer/Customer.utils";
import { createNewUser } from "../utils/user/AdminUser.utils";
import { updateUser } from "../utils/user/User.axios";
import { generateEmptyUser, User, UserRole } from "../utils/user/User.types";

interface CreateOrUpdateUserProps {
  userToEdit?: User;
  updateUserLocal?(user: User): void;
}

const CreateOrUpdateUser: React.FC<CreateOrUpdateUserProps> = ({
  userToEdit,
  updateUserLocal,
}) => {
  const { t } = useTranslation();
  const [user, setUser] = useState<User>(userToEdit || generateEmptyUser());
  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);
  const { axios } = useAxios();

  //loads customers and sets options for customerUid
  useEffect(() => {
    if (axios)
      loadAllCustomers(axios).then((customers) =>
        setCustomerOptions(getOptionsForCustomers(customers))
      );
  }, [axios]);

  /**
   * Helper to submit user after creation/change, will update when user has uid and create
   * a new one otherwise
   */
  const handleSubmit = (): void => {
    if (!axios) {
      console.error(
        "Axios is undefined, therefore creation can't be performed"
      );
      return;
    }
    if (user.uid) updateUser(axios, user).then(() => updateUserLocal?.(user));
    else
      createNewUser(axios, user).then((uid) =>
        updateUserLocal?.({ ...user, uid: uid })
      );
  };

  return (
    <form
      id="create-user"
      onSubmit={(evt) => {
        evt.preventDefault();
        handleSubmit();
      }}
    >
      <InputComponent
        required
        label={t("components.createUser.email")}
        name="email"
        type="email"
        value={user.mail}
        onChange={(value) => setUser((user) => ({ ...user, mail: value }))}
      />
      <InputComponent
        required
        label={t("components.createUser.firstname")}
        name="fname"
        value={user.firstname}
        onChange={(value) => setUser((user) => ({ ...user, firstname: value }))}
      />
      <InputComponent
        required
        label={t("components.createUser.lastname")}
        name="lname"
        value={user.lastname}
        onChange={(value) => setUser((user) => ({ ...user, lastname: value }))}
      />
      <DropdownComponent
        required
        label={t("components.createUser.role")}
        selectedOption={user.role}
        options={Object.values(UserRole).map((role) => ({
          label: t(`enums.userRole.${role}`),
          value: role,
        }))}
        onChange={(value) =>
          setUser((user) => ({ ...user, role: value as UserRole }))
        }
      />
      <DropdownComponent
        required
        label={t("components.createUser.customerUid")}
        selectedOption={user.customerUid}
        options={customerOptions}
        onChange={(value) =>
          setUser((user) => ({ ...user, customerUid: value }))
        }
      />
      <ButtonComponent
        value={t(`buttons.${user.uid ? "save" : "create"}`)}
        form="create-user"
        type="submit"
      />
    </form>
  );
};
export default CreateOrUpdateUser;
