import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LayoutWrapper from "./components/LayoutWrapper";
import PrivateLayoutWrapper from "./components/PrivateLayoutWrapper";
import BeaconAdministration from "./pages/AssetAdministration";
import CustomerAdministration from "./pages/CustomerAdministration";
import Dashboard from "./pages/Dashboard";
import HydrantAdministration from "./pages/hydrant/HydrantAdministration";
import HydrantCreate from "./pages/hydrant/HydrantCreate";
import HydrantEdit from "./pages/hydrant/HydrantEdit";
import Login from "./pages/Login";
import StagingRoomAdministration from "./pages/StagingRoomAdministration";
import MaterialAdministration from "./pages/MaterialAdministration";
import UserAdministration from "./pages/UserAdministration";
import "./tools/Dayjs";
import QualificationAdministration from "./pages/qualification/QualificationAdministration";
import QualificationEdit from "./pages/qualification/QualificationEdit";
import QualificationCreate from "./pages/qualification/QualificationCreate";
import VehicleBlueprintAdministration from "./pages/vehicle/VehicleBlueprintAdministration";
import CreateOrUpdateVehicleBlueprint from "./components/CreateOrUpdateVehicleBlueprint";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LayoutWrapper />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/" element={<PrivateLayoutWrapper />}>
          <Route index element={<Dashboard />} />
          <Route path="/user" element={<UserAdministration />} />
          <Route path="/staging" element={<StagingRoomAdministration />} />
          <Route path="/customer" element={<CustomerAdministration />} />
          <Route path="/assets" element={<BeaconAdministration />} />
          <Route path="/hydrant">
            <Route index element={<HydrantAdministration />} />
            <Route path="edit" element={<HydrantEdit />} />
            <Route path="create" element={<HydrantCreate />} />
          </Route>
          <Route path="/qualification">
            <Route index element={<QualificationAdministration />} />
            <Route path="edit" element={<QualificationEdit />} />
            <Route path="create" element={<QualificationCreate />} />
          </Route>
          <Route path="/vehicle/blueprint">
            <Route index element={<VehicleBlueprintAdministration />} />
            <Route path="form" element={<CreateOrUpdateVehicleBlueprint />} />
          </Route>
          <Route path="/material" element={<MaterialAdministration />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
