import {
  ButtonComponent,
  TableComponent,
  TableRow,
} from "articon-component-library";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import CreateOrUpdateMaterial from "../components/CreateOrUpdateMaterial";
import { useAxios } from "../hooks/useAxios";
import { getAllMaterials } from "../utils/material/Material.axios";
import { Material } from "../utils/material/Material.types";
import { createEmptyMaterial } from "../utils/material/Material.utils";

const MaterialAdministration: React.FC = () => {
  const { t } = useTranslation();
  const { axios } = useAxios();
  const materials = useSWR(axios ? "mission/material/all" : null, () =>
    axios ? getAllMaterials(axios) : []
  );
  const [selectedMaterial, setSelectedMaterial] = useState<Material>();

  /**
   * Memoize table header
   */
  const tableHeader = useMemo<TableRow>(
    () => ({
      value: [{ value: t("pages.materialAdministration.tableHeader.name") }],
    }),
    [t]
  );

  /**
   * Memoize table entries from materials
   */
  const tableEntries = useMemo<TableRow[]>(
    () =>
      (materials.data ?? []).map((material) => ({
        value: [{ value: material.name }],
        onClick: () => setSelectedMaterial(material),
      })),
    [materials.data]
  );

  return selectedMaterial ? (
    <CreateOrUpdateMaterial
      allMaterials={materials.data ?? []}
      material={selectedMaterial}
      setMaterial={setSelectedMaterial}
      onClose={() => setSelectedMaterial(undefined)}
    />
  ) : (
    <div>
      <TableComponent tableHeader={tableHeader} tableEntries={tableEntries} />
      <ButtonComponent
        value={t("pages.materialAdministration.create")}
        onClick={() => setSelectedMaterial(createEmptyMaterial())}
      />
    </div>
  );
};

export default MaterialAdministration;
