import {
  ButtonComponent,
  LayoutComponent,
  MenuGroup,
  MenuItem,
} from "articon-component-library";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../hooks/useUser";
import { logout } from "../utils/FirebaseUtils";
import { buildMenu } from "../utils/Menu.utils";
import { User } from "../utils/user/User.types";

/**
 * This is the user app context
 */
export const UserContext = React.createContext<{
  user?: User;
  updateUser(): Promise<void>;
}>({
  user: undefined,
  updateUser: () => Promise.resolve(),
});

const PrivateLayoutWrapper: React.FC = () => {
  const { user, updateUser, loading } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const menuGroups = useMemo<MenuGroup[]>(() => buildMenu(), []);
  const location = useLocation();

  /**
   * Find the active menu based on the current location
   */
  const activeItem = useMemo<MenuItem | undefined>(
    () =>
      menuGroups
        .flatMap((group) => group.items)
        .filter((item) => location?.pathname.startsWith(item.link ?? ""))
        .sort(
          (itemA, itemB) =>
            (itemB.link?.length ?? 0) - (itemA.link?.length ?? 0)
        )[0],
    [menuGroups, location]
  );

  //redirects to login when user could not be loaded,
  // which means user should not be logged in
  useEffect(() => {
    if (!loading && !user) navigate("/login");
    // eslint-disable-next-line
  }, [loading]);

  return (
    <UserContext.Provider
      value={{
        user: user,
        updateUser: () => updateUser(),
      }}
    >
      <LayoutComponent
        menu={menuGroups}
        menuItem={activeItem}
        onMenuItemClick={(item) =>
          item.link ? navigate(item.link) : undefined
        }
        translations={{ close: t("buttons.close") }}
      >
        <Outlet />
        <ButtonComponent
          value={t("buttons.logout")}
          onClick={() => {
            logout();
            navigate("/login");
          }}
        />
      </LayoutComponent>
    </UserContext.Provider>
  );
};

export default PrivateLayoutWrapper;
