import {
  TableRow,
  TableComponent,
  ButtonComponent,
} from "articon-component-library";
import { useCallback, useMemo } from "react";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useAxios } from "../../hooks/useAxios";
import {
  deleteHydrant,
  getAllHydranten,
} from "../../utils/hydranten/Hydrant.axios";
import { Hydrant } from "../../utils/hydranten/Hydrant.types";
import { createTableRowsForHydranten } from "../../utils/hydranten/Hydrant.utils";

const HydrantAdministration: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { axios } = useAxios();

  const hydranten = useSWR(
    axios ? "hydranten/all" : null,
    () => (!!axios ? getAllHydranten(axios) : []),
    { fallbackData: [] }
  );

  /**
   * Deletes the hydrant on the backend and updates the local hydrant array
   * @param roomUid  uid of the hydrant to be deleted
   */
  const deleteSingleHydrant = useCallback(
    (hydrantUid: string) => {
      if (!axios) return;
      deleteHydrant(axios, hydrantUid).then((success) => {
        if (!success) return;
        hydranten.mutate(
          hydranten.data.filter((hydrant) => hydrant.uid !== hydrantUid)
        );
      });
    },
    [axios, hydranten]
  );

  /**
   * Navigates to the edit page and passes the selected hydrant as state
   */
  const handleOnClick = useCallback(
    (selectedHydrant: Hydrant) => {
      navigate("edit", { state: { selectedHydrant } });
    },
    [navigate]
  );

  /**
   * Memoized table rows for the hydrant table
   */
  const hydrantenEntries: TableRow[] = useMemo(
    () =>
      createTableRowsForHydranten(
        hydranten.data,
        handleOnClick,
        deleteSingleHydrant
      ),
    [hydranten, deleteSingleHydrant, handleOnClick]
  );

  return (
    <>
      <h2>{t("pages.hydrantAdministration.heading")}</h2>

      <TableComponent
        tableHeader={t("pages.hydrantAdministration.tableHeader", {
          returnObjects: true,
        })}
        tableEntries={hydrantenEntries}
      />

      <ButtonComponent
        value={t("buttons.create")}
        onClick={() => navigate("create")}
      />
    </>
  );
};

export default HydrantAdministration;
