import { Material } from "./Material.types";

/**
 * Create an empty material object with default values
 *
 * @param overwrite Optional overwrite specific values
 * @returns The empty material object
 */
export const createEmptyMaterial = (
  overwrite?: Partial<Material>
): Material => ({
  uid: "",
  createDate: new Date(),
  lastUpdate: new Date(),
  name: "",
  ...overwrite,
});
