import React, { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import {
  StandardLoad,
  VehicleBlueprint,
  VehicleType,
  generateEmptyVehicleBlueprint,
  generateEmptyVehicleType,
} from "../utils/vehicle/VehicleBlueprint.types";
import { useTranslation } from "react-i18next";
import { useAxios } from "../hooks/useAxios";
import {
  InputComponent,
  DropdownComponent,
  ButtonComponent,
  CheckboxComponent,
  Option,
} from "articon-component-library";

import {
  createVehicleBlueprint,
  getAllVehicleTypes,
  updateVehicleBlueprint,
} from "../utils/vehicle/VehicleBlueprint.axios";
import { getAllMaterials } from "../utils/material/Material.axios";
import { createEmptyMaterial } from "../utils/material/Material.utils";
import { useLocation, useNavigate } from "react-router";
import {
  getVehicleIconUrl,
  saveVehicleBlueprintIcon,
} from "../utils/vehicle/VehicleBlueprint.firebase";

import { ReactComponent as DeleteIcon } from "../assets/delete.svg";

export interface CreateOrUpdateVehicleBlueprintProps {}

const CreateOrUpdateVehicleBlueprint: React.FC<
  CreateOrUpdateVehicleBlueprintProps
> = () => {
  const { t } = useTranslation();
  const { axios } = useAxios();

  const locationState = useLocation();

  const selectedVehicleBlueprint: VehicleBlueprint | undefined = useMemo(
    () => locationState.state?.selectedVehicleBlueprint,
    [locationState]
  );

  const navigate = useNavigate();
  const [vehicleBlueprint, setVehicleBlueprint] = useState<VehicleBlueprint>(
    selectedVehicleBlueprint || generateEmptyVehicleBlueprint()
  );
  const [newVehicleType, setNewVehicleType] = useState<VehicleType>(
    generateEmptyVehicleType()
  );

  const [selectedFile, setSelectedFile] = useState<File>();
  const [iconUrl, setIconUrl] = useState<string>("");
  const [createNewVehicleType, toggleNewVehicleType] = useState<boolean>(false);

  /**
   * Sets the vehicleBlueprint to the vehicleBlueprintEdit if it is defined
   */
  useEffect(() => {
    if (selectedVehicleBlueprint) {
      getVehicleIconUrl(selectedVehicleBlueprint.uid).then(setIconUrl);
    }
  }, [selectedVehicleBlueprint]);

  const vehicleTypes = useSWR(
    axios ? "/vehicleTypes" : null,
    () => (axios ? getAllVehicleTypes(axios) : null),
    {
      fallbackData: [],
    }
  );

  const materials = useSWR(axios ? "mission/material/all" : null, () =>
    axios ? getAllMaterials(axios) : []
  );

  /**
   * Create dropdown options for materials
   * @param load - The load for which the material should be changed ;
   * @returns  {Option[]}
   */
  const createMaterialDropdownOptions = (load: StandardLoad): Option[] => {
    return (
      materials.data?.map((material) => ({
        label: material.name,
        value: material.uid,
        disabled:
          load.material.uid === material.uid ||
          vehicleBlueprint.standardLoad.some(
            (load) => load.material.uid === material.uid
          ),
      })) ?? []
    );
  };

  /**
   * Handler for when the {@link Material} changes for a {@link StandardLoad}
   * @param materialUid - The uid of the {@link Material}
   * @param index  - The index of the {@link StandardLoad}
   */
  const onChangeMaterialForStandardLoad = (
    materialUid: string,
    index: number
  ): void => {
    const copiedStandardLoad = [...vehicleBlueprint.standardLoad];
    copiedStandardLoad[index].material =
      materials.data?.find((material) => material.uid === materialUid) ||
      createEmptyMaterial();
    setVehicleBlueprint((blueprint) => ({
      ...blueprint,
      standardLoad: copiedStandardLoad,
    }));
  };

  /**
   * Handler for when the amount of a {@link StandardLoad} changes
   * @param amount  - The new amount
   * @param index  - The index of the {@link StandardLoad}
   */
  const onChangeMaterialAmountOfStandardLoad = (
    amount: number,
    index: number
  ): void => {
    const copiedStandardLoad = [...vehicleBlueprint.standardLoad];
    copiedStandardLoad[index].amount = amount;
    setVehicleBlueprint((blueprint) => ({
      ...blueprint,
      standardLoad: copiedStandardLoad,
    }));
  };

  /**
   * Handler the deletion of a {@link StandardLoad}
   * @param index  - The index of the {@link StandardLoad}
   */
  const handleDeletionOfStandardLoad = (index: number): void => {
    const copiedStandardLoad = [...vehicleBlueprint.standardLoad];
    copiedStandardLoad.splice(index, 1);
    setVehicleBlueprint((blueprint) => ({
      ...blueprint,
      standardLoad: copiedStandardLoad,
    }));
  };

  /**
   * Renders the standard load of the vehicle blueprint
   * @returns {JSX.Element[]}
   */
  const renderStandardLoad = (): JSX.Element[] => {
    const standardLoadElements: JSX.Element[] =
      vehicleBlueprint.standardLoad.map((load, index) => {
        return (
          <div className="blueprint-material-amount-wrapper">
            <DropdownComponent
              label={t("components.createBlueprint.material")}
              required
              options={createMaterialDropdownOptions(load)}
              selectedOption={load.material.uid}
              onChange={(materialUid) =>
                onChangeMaterialForStandardLoad(materialUid, index)
              }
            />
            <InputComponent
              label={t("components.createBlueprint.amount")}
              value={load.amount}
              type="number"
              min={0}
              onChangeNumber={(amount) =>
                onChangeMaterialAmountOfStandardLoad(amount, index)
              }
            />
            <DeleteIcon
              className="delete-icon"
              onClick={() => handleDeletionOfStandardLoad(index)}
            />
          </div>
        );
      });
    return [
      ...standardLoadElements,
      standardLoadElements.length >= (materials.data?.length ?? 0) ? (
        <></>
      ) : (
        <ButtonComponent
          value={t("components.createBlueprint.addMaterial")}
          onClick={() =>
            setVehicleBlueprint((blueprint) => ({
              ...blueprint,
              standardLoad: [
                ...blueprint.standardLoad,
                { material: createEmptyMaterial(), amount: 0 },
              ],
            }))
          }
        />
      ),
    ];
  };

  /**
   * Handles the submit of the form
   */
  const handleSubmit = async (): Promise<void> => {
    if (!axios) return;
    let vehicleBlueprintUid: string = vehicleBlueprint.uid;
    if (vehicleBlueprintUid)
      await updateVehicleBlueprint(axios, {
        ...vehicleBlueprint,
        type:
          createNewVehicleType || (vehicleTypes.data?.length ?? -1) <= 0
            ? newVehicleType
            : vehicleBlueprint.type,
      });
    else
      vehicleBlueprintUid = await createVehicleBlueprint(axios, {
        ...vehicleBlueprint,
        type:
          createNewVehicleType || (vehicleTypes.data?.length ?? -1) <= 0
            ? newVehicleType
            : vehicleBlueprint.type,
      });
    if (selectedFile)
      await saveVehicleBlueprintIcon(selectedFile, vehicleBlueprintUid);
    navigate(-1);
  };

  const dropdownForVehicleTypeVisible: boolean =
    !!vehicleTypes.data &&
    vehicleTypes.data.length > 0 &&
    !createNewVehicleType;

  return (
    <form
      id="create-blueprint"
      onSubmit={(evt) => {
        evt.preventDefault();
        handleSubmit();
      }}
    >
      <InputComponent
        required
        label={t("components.createBlueprint.name")}
        value={vehicleBlueprint.name}
        onChange={(name) =>
          setVehicleBlueprint((blueprint) => ({ ...blueprint, name }))
        }
      />
      <InputComponent
        required
        type="number"
        label={t("components.createBlueprint.seats")}
        value={vehicleBlueprint.seats}
        onChangeNumber={(seats) =>
          setVehicleBlueprint((blueprint) => ({ ...blueprint, seats }))
        }
      />
      {vehicleTypes.data && vehicleTypes.data?.length > 0 && (
        <CheckboxComponent
          checked={createNewVehicleType}
          onCheck={toggleNewVehicleType}
          value={t("components.createBlueprint.isNewType")}
        />
      )}

      {dropdownForVehicleTypeVisible ? (
        <DropdownComponent
          label={t("components.createBlueprint.type")}
          required
          options={
            vehicleTypes.data?.map((type) => ({
              label: type.name,
              value: type.uid,
            })) || []
          }
          selectedOption={vehicleBlueprint.type.uid || ""}
          onChange={(typeUid) =>
            setVehicleBlueprint((blueprint) => ({
              ...blueprint,
              type: vehicleTypes.data!.find((type) => type.uid === typeUid)!,
            }))
          }
        />
      ) : (
        <InputComponent
          required
          label={t("components.createBlueprint.newType")}
          value={newVehicleType.name}
          onChange={(name) =>
            setNewVehicleType((vehicleType) => ({ ...vehicleType, name }))
          }
        />
      )}

      {renderStandardLoad()}

      <div className="blueprint-icon-wrapper">
        <InputComponent
          type="file"
          label={t("components.createBlueprint.icon")}
          onChangeFile={setSelectedFile}
          acceptedFileTypes={["image/*"]}
        />
        {(selectedFile || iconUrl) && (
          <img
            alt={t("components.createBlueprint.imageAlt")}
            src={
              selectedFile
                ? URL.createObjectURL(selectedFile)
                : iconUrl
                ? iconUrl
                : ""
            }
          />
        )}
      </div>

      <ButtonComponent
        value={t(`buttons.${vehicleBlueprint.uid ? "save" : "create"}`)}
        form="create-blueprint"
        type="submit"
      />
    </form>
  );
};

export default CreateOrUpdateVehicleBlueprint;
