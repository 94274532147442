import { ButtonComponent, InputComponent } from "articon-component-library";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { useAxios } from "../hooks/useAxios";
import {
  createMaterial,
  deleteMaterial,
  updateMaterial,
} from "../utils/material/Material.axios";
import { Material } from "../utils/material/Material.types";

const CreateOrUpdateMaterial: React.FC<{
  allMaterials: Material[];
  material: Material;
  setMaterial: Dispatch<SetStateAction<Material | undefined>>;
  onClose: () => void;
}> = ({ allMaterials, material, setMaterial, onClose }) => {
  const { t } = useTranslation();
  const { axios } = useAxios();

  /**
   * Handle save button click
   */
  const handleSave = useCallback(async () => {
    if (!axios) return;
    const updatedMaterial: Material = await (material.uid
      ? updateMaterial(axios, material)
      : createMaterial(axios, material));
    await mutate(
      "mission/material/all",
      allMaterials.map((entry) =>
        entry.uid === updatedMaterial.uid ? updatedMaterial : entry
      )
    );
    onClose();
  }, [material, allMaterials, axios, onClose]);

  /**
   * Handle delete button click
   */
  const handleDelete = useCallback(async () => {
    if (!axios || !material.uid) return;
    await deleteMaterial(axios, material.uid);
    await mutate(
      "mission/material/all",
      allMaterials.filter((entry) => entry.uid !== material.uid)
    );
    onClose();
  }, [axios, material.uid, onClose, allMaterials]);

  return (
    <>
      <InputComponent
        label={t("pages.materialAdministration.name")}
        value={material.name}
        onChange={(name) => setMaterial({ ...material, name })}
      />
      <ButtonComponent value={t("buttons.back")} onClick={onClose} />
      <ButtonComponent
        value={material.uid ? t("buttons.save") : t("buttons.create")}
        onClick={handleSave}
      />
      {material.uid && (
        <ButtonComponent value={t("buttons.delete")} onClick={handleDelete} />
      )}
    </>
  );
};

export default CreateOrUpdateMaterial;
