import {
  InputComponent,
  ButtonComponent,
  DropdownComponent,
} from "articon-component-library";
import useSWR from "swr";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAxios } from "../hooks/useAxios";
import {
  createNewStagingRoom,
  updateStagingRoom,
} from "../utils/staging/Staging.axios";
import {
  generateEmptyStagingRoom,
  StagingRoom,
  StagingRoomStatus,
} from "../utils/staging/Staging.types";
import { createDropDownOptionsForStagingRoomStatus } from "../utils/staging/Staging.utils";
import { loadAllCustomers } from "../utils/customer/Customer.axios";
import { getOptionsForCustomers } from "../utils/customer/Customer.utils";
import { AddressForm } from "./AddressForm";

interface CreateOrUpdateStagingRoomProps {
  stagingRoomToEdit?: StagingRoom;
  updateStagingRoomLocal?(stagingRoom: StagingRoom): void;
}

const CreateOrUpdateStagingRoom: React.FC<CreateOrUpdateStagingRoomProps> = ({
  stagingRoomToEdit,
  updateStagingRoomLocal,
}) => {
  const { t } = useTranslation();
  const [stagingRoom, setStagingRoom] = useState<StagingRoom>(
    stagingRoomToEdit || generateEmptyStagingRoom()
  );
  const { axios } = useAxios();

  const customers = useSWR(
    axios ? "user/customer/all" : null,
    () => (axios ? loadAllCustomers(axios) : []),
    { fallbackData: [] }
  );

  /**
   * Helper to submit customer after creation/change, will update when customer has uid and create
   * a new one otherwise
   */
  const handleSubmit = (): void => {
    if (!axios) {
      console.error(
        "Axios is undefined, therefore creation can't be performed"
      );
      return;
    }
    if (stagingRoom.uid)
      updateStagingRoom(axios, stagingRoom).then(() =>
        updateStagingRoomLocal?.(stagingRoom)
      );
    else
      createNewStagingRoom(axios, stagingRoom).then((uid) =>
        updateStagingRoomLocal?.({ ...stagingRoom, uid: uid })
      );
  };

  return (
    <form
      id="create-staging"
      onSubmit={(evt) => {
        evt.preventDefault();
        handleSubmit();
      }}
    >
      <InputComponent
        required
        label={t("components.createStaging.name")}
        value={stagingRoom.name}
        onChange={(name) => setStagingRoom((staging) => ({ ...staging, name }))}
      />
      <AddressForm
        initAddress={stagingRoom.address}
        initCoordinates={[stagingRoom.lat, stagingRoom.lon]}
        onChange={(address, coordinates) =>
          setStagingRoom((current) => ({
            ...current,
            address,
            lat: coordinates[0],
            lng: coordinates[1],
          }))
        }
      />
      <DropdownComponent
        label={t("components.createStaging.status")}
        options={createDropDownOptionsForStagingRoomStatus()}
        selectedOption={stagingRoom.status}
        onChange={(value) =>
          setStagingRoom((staging) => ({
            ...staging,
            status: value as StagingRoomStatus,
          }))
        }
      />
      <DropdownComponent
        label={t("components.createStaging.customer")}
        options={getOptionsForCustomers(customers.data)}
        selectedOption={stagingRoom.customerUid}
        onChange={(value) =>
          setStagingRoom((staging) => ({
            ...staging,
            customerUid: value,
          }))
        }
      />

      <ButtonComponent
        value={t(`buttons.${stagingRoom.uid ? "save" : "create"}`)}
        form="create-staging"
        type="submit"
      />
    </form>
  );
};
export default CreateOrUpdateStagingRoom;
