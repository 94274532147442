import { LoaderComponent } from "articon-component-library";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateOrUpdateHydrant from "../../components/CreateOrUpdateHydrant";
import { Hydrant } from "../../utils/hydranten/Hydrant.types";

const HydrantEdit: React.FC = () => {
  const navigate = useNavigate();
  const locationState = useLocation();

  const selectedHydrant: Hydrant | undefined = useMemo(
    () => locationState.state?.selectedHydrant,
    [locationState]
  );
  return selectedHydrant ? (
    <CreateOrUpdateHydrant
      hydrantToEdit={selectedHydrant}
      updateHydrantLocal={() => navigate(-1)}
    />
  ) : (
    <LoaderComponent />
  );
};

export default HydrantEdit;
