import { DropdownComponent, TableRow } from "articon-component-library";
import i18n from "../../i18n";
import { Customer } from "../customer/Customer.types";
import { getOptionsForCustomers } from "../customer/Customer.utils";
import { Beacon } from "./Beacon.types";
import dayjs from "dayjs";

/**
 * Util method to create table rows for beacons
 * @param beacons  The beacons to create table rows for
 * @param customers  The customers to create table rows for
 * @param onClickRow  The function to call when a row is clicked
 * @param onDeleteRow  The function to call when a row is deleted
 * @param updateSingleBeacon  The function to call when a single beacon is updated
 * @returns  The table rows for the beacons
 */
export const createTableRowsForBeacons = (
  beacons: Beacon[],
  customers: Customer[],
  onClickRow: (beacon: Beacon) => void,
  onDeleteRow: (beacon: Beacon) => void,
  updateSingleBeacon: (beacon: Beacon) => void
): TableRow[] => {
  const beaconEntries: TableRow[] = [];
  beacons.forEach((beacon) => {
    const beaconRow: TableRow = createTableRowForBeacon(
      beacon,
      customers,
      onClickRow,
      onDeleteRow,
      updateSingleBeacon
    );
    beaconEntries.push(beaconRow);
  });
  return beaconEntries;
};

/**
 * Util method to create a table row for a beacon
 * @param beacon  The beacon to create a table row for
 * @param customers  The customers to create a table row for
 * @param onClick  The function to call when a row is clicked
 * @param onDelete  The function to call when a row is deleted
 * @param updateSingleBeacon  The function to call when a single beacon is updated
 * @returns  The table row for the beacon
 */
export const createTableRowForBeacon = (
  beacon: Beacon,
  customers: Customer[],
  onClick: (beacon: Beacon) => void,
  onDelete: (beacon: Beacon) => void,
  updateSingleBeacon: (beacon: Beacon) => void
): TableRow => {
  const beaconRow: TableRow = {
    value: [
      { value: beacon.uid },
      { value: beacon.name },
      { value: beacon.model },
      { value: i18n.t("pages.assetsAdministration.beacon.type") },
      {
        value: (
          <DropdownComponent
            options={getOptionsForCustomers(customers)}
            selectedOption={beacon.customerUid}
            onChange={(customerUid) => {
              updateSingleBeacon({ ...beacon, customerUid });
            }}
          />
        ),
      },
      { value: dayjs(beacon.lastUpdated).fromNow() },
      { value: dayjs(beacon.lastSynced).fromNow() },
    ],
    onClick: () => onClick(beacon),
    onClickMobile: () => onClick(beacon),
  };
  return beaconRow;
};
