import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { User } from "./User.types";

export const userServiceBaseUrl: string =
  process.env.REACT_APP_USER_SERVICE_URL || "";

export const missionServiceBaseUrl: string =
  process.env.REACT_APP_MISSION_SERVICE_URL || "";

/**
 * Updates User on Database
 *
 * @param axios
 * @param user
 * @returns true if update was successful, false otherwise
 */
export const updateUser = async (
  axios: AxiosInstance,
  user: User
): Promise<boolean> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve(false);
  return axios
    .post(`${userServiceBaseUrl}/update/`, user)
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("User update couldn't be performed", err.message);
      return false;
    });
};

/**
 * API method to load a user identified by their uid
 *
 * @param axios
 * @param uid The uid of the user to load
 * @returns found user or null
 */
export const loadUser = async (
  axios: AxiosInstance,
  uid: string
): Promise<User | null> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve(null);
  return axios
    .get(userServiceBaseUrl, { params: { userUid: uid } })
    .then((res) => res.data)
    .catch((err) => {
      console.error("User couldn't be loaded", err.message);
      return null;
    });
};
