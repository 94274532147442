import { VehicleBlueprint, VehicleType } from "./VehicleBlueprint.types";
import { missionServiceBaseUrl } from "../user/User.axios";
import { AxiosInstance } from "axios";

/**
 * Get all vehicle blueprints
 * @param axios  The axios instance
 * @returns  {Promise<VehicleBlueprint[]>}  The vehicle blueprints
 */
export const getAllVehicleBlueprint = async (
  axios: AxiosInstance
): Promise<VehicleBlueprint[]> =>
  axios
    .get(`${missionServiceBaseUrl}/vehicle/blueprint/`)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Vehicle blueprints couldn't be loaded", err);
      return [];
    });

/**
 * Get all vehicle types
 * @param axios  The axios instance
 * @returns  {Promise<VehicleType[]>}  The vehicle types
 */
export const getAllVehicleTypes = async (
  axios: AxiosInstance
): Promise<VehicleType[]> =>
  axios
    .get(`${missionServiceBaseUrl}/vehicle/type/`)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Vehicle types couldn't be loaded", err);
      return [];
    });

/**
 * Create a new vehicle blueprint
 *
 * @param axios The axios instance
 * @param newVehicleBlueprint The vehicle blueprint to create (currently only name is used)
 * @returns {@string} The uid of the newly created vehicle blueprint
 */
export const createVehicleBlueprint = async (
  axios: AxiosInstance,
  newVehicleBlueprint: VehicleBlueprint
): Promise<string> =>
  axios
    .post(`${missionServiceBaseUrl}/vehicle/blueprint/`, newVehicleBlueprint)
    .then((res) => {
      if (res.status === 201) return res.data;
      throw new Error("Received unexpected status code: " + res.status);
    })
    .catch((err) => {
      console.error("Vehicle blueprint couldn't be created", err);
      return "";
    });

/**
 * Update a vehicle blueprint
 * @param axios The axios instance
 * @param vehicleBlueprint The vehicle blueprint to update
 * @returns  {Promise<boolean>}  True if the update was successful
 */
export const updateVehicleBlueprint = async (
  axios: AxiosInstance,
  vehicleBlueprint: VehicleBlueprint
): Promise<boolean> =>
  axios
    .post(
      `${missionServiceBaseUrl}/vehicle/blueprint/update/`,
      vehicleBlueprint
    )
    .then((res) => {
      if (res.status === 200) return true;
      throw new Error("Received unexpected status code: " + res.status);
    })
    .catch((err) => {
      console.error("Vehicle blueprint couldn't be updated", err);
      return false;
    });
