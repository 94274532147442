/**
 * The role defines what permissions the user has
 */
export enum UserRole {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  UNIT_ADMIN = "UNIT_ADMIN",
  SUPER_USER = "SUPER_USER",
  USER = "USER",
  UNIT_USER = "UNIT_USER",
}

/**
 * This is the basic user
 */
export interface User {
  uid: string;
  firstname: string;
  lastname: string;
  mail: string;
  role: UserRole;
  customerUid: string;
  [x: string]: any;
}

/**
 * generates a new empty user for creation
 * @returns empty user
 */
export const generateEmptyUser = (): User => ({
  uid: "",
  firstname: "",
  lastname: "",
  mail: "",
  role: UserRole.UNIT_USER,
  customerUid: "",
});
