import { ButtonComponent, TableComponent } from "articon-component-library";
import { getAllVehicleBlueprint } from "../../utils/vehicle/VehicleBlueprint.axios";
import useSWR from "swr";
import { createTableRowsForVehicleBlueprints } from "../../utils/vehicle/VehicleBlueprint.utils";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useAxios } from "../../hooks/useAxios";

const VehicleBlueprintAdministration: React.FC = () => {
  const { axios } = useAxios();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const vehicleBlueprints = useSWR(
    axios ? "/mission/vehicle/blueprint" : null,
    () => (!!axios ? getAllVehicleBlueprint(axios) : []),
    { fallbackData: [] }
  );

  return (
    <>
      <h2>{t("pages.vehicleBlueprintAdministration.heading")}</h2>

      <TableComponent
        tableHeader={t("pages.vehicleBlueprintAdministration.tableHeader", {
          returnObjects: true,
        })}
        tableEntries={createTableRowsForVehicleBlueprints(
          vehicleBlueprints.data,
          (blueprint) =>
            navigate("form", {
              state: { selectedVehicleBlueprint: blueprint },
            })
        )}
      />

      <ButtonComponent
        value={t("buttons.create")}
        onClick={() => navigate("form")}
      />
    </>
  );
};

export default VehicleBlueprintAdministration;
