import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { userServiceBaseUrl } from "../user/User.axios";
import { Customer } from "./Customer.types";

/**
 * API method to load all customers
 *
 * @param axios
 * @returns The list of all customers, can be empty
 */
export const loadAllCustomers = (axios: AxiosInstance): Promise<Customer[]> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${userServiceBaseUrl}/customer/all/`)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Customers couldn't be loaded", err.message);
      return [];
    });
};

/**
 * creates given Customer in firebase
 *
 * @param axios
 * @param newCustomer new customer object without uid
 * @returns customerUid when successful, else empty string
 */
export const createNewCustomer = (
  axios: AxiosInstance,
  newCustomer: Customer
): Promise<string> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve("");
  return axios
    .post(`${userServiceBaseUrl}/customer/`, newCustomer)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error while creating customer", err.message);
      return "";
    });
};

/**
 * updates given Customer in firebase
 *
 * @param axios
 * @param customer updated customer object
 * @returns true when update was successful, false otherwise
 */
export const updateCustomer = (
  axios: AxiosInstance,
  customer: Customer
): Promise<boolean> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve(false);
  return axios
    .post(`${userServiceBaseUrl}/customer/update/`, customer)
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Error while updating customers", err.message);
      return false;
    });
};

/**
 * fetches customer for given uid
 * @param axios
 * @param customerUid uid of customer to load
 * @returns customer when successful or null on error
 */
export const getCustomer = (
  axios: AxiosInstance,
  customerUid: string
): Promise<Customer | null> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve(null);
  return axios
    .get(`${userServiceBaseUrl}/customer/history`, {
      params: { customerUid: customerUid, index: 0 },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error while loading customer", err.message);
      return null;
    });
};

/**
 * deletes customer for given Uid
 * @param axios
 * @param customerUid uid of customer to delete
 */
export const deleteCustomer = (
  axios: AxiosInstance,
  customerUid: string
): Promise<boolean> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve(false);
  return axios
    .get(`${userServiceBaseUrl}/customer/delete/`, {
      params: { customerUid: customerUid },
    })
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Error while deleting customer", err.message);
      return false;
    });
};
