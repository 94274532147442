import { TableRow } from "articon-component-library";
import { Qualification, QualificationGroup } from "./Qualification.types";
import i18n from "../../i18n";

/**
 * Util to create table rows for qualifications
 * @param qualifications - The qualifications to create table rows for
 * @param groups -  The qualification groups to use for the table rows
 * @param onClickRow  - The function to call when a row is clicked
 * @returns {TableRow[]} - The table rows
 */
export const createTableRowsForQualifications = (
  qualifications: Qualification[],
  groups: QualificationGroup[],
  onClickRow: (qualification: Qualification) => void
): TableRow[] => {
  return qualifications.map((qualification) =>
    createTableRowEntryForQualification(qualification, groups, onClickRow)
  );
};

/**
 * Util to create a table row for a qualification
 * @param qualification - The qualification to create a table row for
 * @param groups  - The qualification groups to use for the table row
 * @param onClick  - The function to call when the table row is clicked
 * @returns  {TableRow} - The table row
 */
export const createTableRowEntryForQualification = (
  qualification: Qualification,
  groups: QualificationGroup[],
  onClick: (qualification: Qualification) => void
): TableRow => ({
  value: [
    {
      value: qualification.name,
    },
    {
      value:
        groups.find((group) => group.uid === qualification.groupId)?.name ||
        i18n.t("pages.qualificationAdministration.noGroup"),
    },
  ],
  onClick: () => onClick(qualification),
  onClickMobile: () => onClick(qualification),
});
