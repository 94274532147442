import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { Qualification, QualificationGroup } from "./Qualification.types";

const userServiceBaseUrl: string = process.env.REACT_APP_USER_SERVICE_URL || "";

/**
 * Fetches all qualifications
 * @param {AxiosInstance} axios - The axios instance to use for the request.
 * @returns {Promise<QualificationGroup[]>} - A Promise that resolves with the fetched Qualifications
 */
export const getQualifications = (
  axios: AxiosInstance
): Promise<Qualification[]> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${userServiceBaseUrl}/qualification/`)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error while fetching qualifications", err.message);
      return [];
    });
};

/**
 * Fetches all qualification groups
 * @param {AxiosInstance} axios - The axios instance to use for the request.
 * @returns {Promise<QualificationGroup[]>} - A Promise that resolves with the fetched QualificationGroups.
 */
export const getQualificationGroups = (
  axios: AxiosInstance
): Promise<QualificationGroup[]> => {
  if (isBaseUrlInvalid(userServiceBaseUrl)) return Promise.resolve([]);
  return axios
    .get(`${userServiceBaseUrl}/qualification/group/`)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error while fetching qualification groups", err.message);
      return [];
    });
};

/**
 * Creates a new qualification group using a post request to the API.
 * @param {AxiosInstance} axios - The axios instance to use for the request.
 * @param {QualificationGroup} group - The QualificationGroup object to be created.
 * @returns {Promise<QualificationGroup>} - A Promise that resolves with the created QualificationGroup object.
 */
export const createQualificationGroup = (
  axios: AxiosInstance,
  group: QualificationGroup
): Promise<string> => {
  if (isBaseUrlInvalid(userServiceBaseUrl))
    return Promise.reject(new Error("Invalid base URL"));
  return axios
    .post(`${userServiceBaseUrl}/qualification/group/`, group)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error while creating qualification group", err);
      throw err;
    });
};

/**
Update a QualificationGroup in the database.
* @param {AxiosInstance} axios - AxiosInstance to perform the HTTP request.
* @param {QualificationGroup} group - The QualificationGroup to be updated.
* @returns {Promise<boolean>} - 200 when update successful, 400 when update failed.
*/
export const updateQualificationGroup = (
  axios: AxiosInstance,
  group: QualificationGroup
): Promise<boolean> => {
  if (isBaseUrlInvalid(userServiceBaseUrl))
    return Promise.reject(new Error("Invalid base URL"));
  return axios
    .post(`${userServiceBaseUrl}/qualification/group/update/`, group)
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Error while updating qualification group", err);
      throw err;
    });
};

/**
 * @param {AxiosInstance} axios - AxiosInstance to perform the HTTP request.
 * @param {QualificationGroup} group - The QualificationGroup to be updated.
 * @returns {Promise<boolean>} - 200 when update successful, 400 when update failed.
 */
export const updateQualification = (
  axios: AxiosInstance,
  qualification: Qualification
): Promise<boolean> => {
  if (isBaseUrlInvalid(userServiceBaseUrl))
    return Promise.reject(new Error("Invalid base URL"));
  return axios
    .post(`${userServiceBaseUrl}/qualification/update/`, qualification)
    .then((res) => res.status === 200)
    .catch((err) => {
      console.error("Error while updating qualification ", err);
      throw err;
    });
};

/**
 * Creates a new qualification using a post request to the API.
 * @param {AxiosInstance} axios - The axios instance to use for the request.
 * @param {Qualification} qualification - The Qualification object to be created.
 * @returns {Promise<Qualification>} - A Promise that resolves with the created Qualification object.
 */
export const createQualification = (
  axios: AxiosInstance,
  qualification: Qualification
): Promise<Qualification> => {
  if (isBaseUrlInvalid(userServiceBaseUrl))
    return Promise.reject(new Error("Invalid base URL"));
  return axios
    .post(`${userServiceBaseUrl}/qualification/`, qualification)
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error while creating qualification", err);
      throw err;
    });
};
