import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  InputComponent,
} from "articon-component-library";
import { useSWRConfig } from "swr";
import {
  Qualification,
  QualificationGroup,
  generateEmptyQualificationGroup,
  generateEmptyQualification,
  QualificationDisplayType,
} from "../utils/qualifications/Qualification.types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAxios } from "../hooks/useAxios";
import {
  createQualification,
  createQualificationGroup,
  updateQualification,
} from "../utils/qualifications/Qualification.axios";
import { useNavigate } from "react-router";

interface CreateOrUpdateQualificationProps {
  qualificationToEdit?: Qualification;
}

const CreateOrUpdateQualification: React.FC<
  CreateOrUpdateQualificationProps
> = ({ qualificationToEdit }) => {
  const { t } = useTranslation();
  const [qualification, setQualification] = useState<Qualification>(
    qualificationToEdit || generateEmptyQualification()
  );
  const { axios } = useAxios();
  const navigate = useNavigate();

  const { cache, mutate } = useSWRConfig();

  const qualificationGroups: QualificationGroup[] =
    cache.get("/qualification/groups")?.data || [];

  const [isGroupNew, toggleGroupNew] = useState<boolean>(
    qualificationGroups.length === 0 || false
  );
  const [isNoGroup, toggleNoGroup] = useState<boolean>(
    qualificationToEdit ? !qualificationToEdit?.groupId : false
  );

  const [qualificationGroup, setQualificationGroup] =
    useState<QualificationGroup>(
      qualificationGroups.find(
        (group) => group.uid === qualification.groupId
      ) || generateEmptyQualificationGroup()
    );

  /**
   * Handles the submit of the form and creates or updates the qualification/group
   * @returns
   */
  const handleSubmit = async (): Promise<void> => {
    if (!axios) return;
    let submitQualification: Qualification = { ...qualification };
    if (isGroupNew && !isNoGroup) {
      const groupId: string = await createQualificationGroup(
        axios,
        qualificationGroup
      );
      submitQualification = { ...submitQualification, groupId };
    }
    if (isNoGroup) submitQualification.groupId = undefined;
    await (qualification.uid
      ? updateQualification(axios, submitQualification)
      : createQualification(axios, submitQualification));

    mutate("/qualifications");
    mutate("/qualification/groups");
    navigate(-1);
  };

  return (
    <form
      id="create-qualification"
      onSubmit={(evt) => {
        evt.preventDefault();
        handleSubmit();
      }}
    >
      <InputComponent
        required
        label={t("components.createQualification.name")}
        value={qualification.name}
        onChange={(name) =>
          setQualification((qualification) => ({ ...qualification, name }))
        }
      />

      <p className="qualification-display__header">
        {t("components.createQualification.displayType.header")}
      </p>
      <div className="qualification-display__container">
        <CheckboxComponent
          onCheck={() =>
            setQualification((oldQualification) => ({
              ...oldQualification,
              displayType: QualificationDisplayType.PRIMARY,
            }))
          }
          checked={
            qualification.displayType === QualificationDisplayType.PRIMARY
          }
          value={t("components.createQualification.displayType.primary")}
        />
        <CheckboxComponent
          onCheck={() =>
            setQualification((oldQualification) => ({
              ...oldQualification,
              displayType: QualificationDisplayType.SECONDARY,
            }))
          }
          checked={
            qualification.displayType === QualificationDisplayType.SECONDARY
          }
          value={t("components.createQualification.displayType.secondary")}
        />
      </div>
      <div className="qualification-display__container">
        <CheckboxComponent
          onCheck={toggleNoGroup}
          checked={isNoGroup}
          value={t("components.createQualification.noGroup")}
        />
        {!isNoGroup && (
          <CheckboxComponent
            onCheck={toggleGroupNew}
            checked={isGroupNew}
            value={t("components.createQualification.newGroup")}
          />
        )}
      </div>
      {!isNoGroup &&
        (isGroupNew ? (
          <InputComponent
            required
            label={t("components.createQualification.group")}
            value={qualificationGroup.name}
            onChange={(name) =>
              setQualificationGroup((group) => ({ ...group, name }))
            }
          />
        ) : (
          <DropdownComponent
            required
            label={t("components.createQualification.group")}
            options={qualificationGroups.map((group) => ({
              value: group.uid,
              label: group.name,
            }))}
            selectedOption={qualification.groupId}
            onChange={(groupId) => {
              setQualification({
                ...qualification,
                groupId,
              });
            }}
          />
        ))}

      <ButtonComponent
        value={t(`buttons.${qualification.uid ? "save" : "create"}`)}
        form="create-qualification"
        type="submit"
      />
    </form>
  );
};

export default CreateOrUpdateQualification;
