import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { loadUser } from "../utils/user/User.axios";
import { User } from "../utils/user/User.types";
import { useAxios } from "./useAxios";

/**
 * Central user hook to quickly access the user object and some helper
 * methods/variables
 *
 * @returns The user object and some helper
 */
export const useUser = (): {
  user: User;
  loading: boolean;
  updateUser: Function;
} => {
  const [user, loading] = useAuthState(auth);
  const [loadedUser, setUser] = useState<User>();
  const [internalLoading, toggleInternalLoading] = useState<boolean>(true);
  const { axios } = useAxios();

  /**
   * Helper method to load the user object from the database
   */
  const fetchUserObject = async () => {
    if (!auth.currentUser || !axios) return;
    const user: User | null = await loadUser(axios, auth.currentUser.uid);
    if (!user) {
      console.error("User doesn't exist");
      toggleInternalLoading(false);
      return;
    }
    setUser(user);
    toggleInternalLoading(false);
  };

  // as soon as the auth user is defined trigger the user loading
  useEffect(() => {
    // index 0 holds the loaded user object and index 1 holds the information
    // if the login is still pending. Only if both are false there is no
    // logged in user at all!
    if (!user && !loading) {
      setUser(undefined);
      toggleInternalLoading(false);
      return;
    }
    if (user) fetchUserObject();
    // eslint-disable-next-line
  }, [user, loading, axios]);

  return {
    user: loadedUser!,
    loading: internalLoading,
    updateUser: fetchUserObject,
  };
};
