import { AxiosInstance } from "axios";
import { isBaseUrlInvalid } from "../Axios.utils";
import { missionServiceBaseUrl } from "../user/User.axios";
import { Geocoding } from "./Geocoding.types";

/**
 * Get geocoding data from the mission service.
 *
 * @param axios The axios instance
 * @param search The search string (concatenated address)
 * @returns The geocoding data or throw if no geocoding was found
 */
export const getGeocoding = async (
  axios: AxiosInstance,
  search: string
): Promise<Geocoding> => {
  if (isBaseUrlInvalid(missionServiceBaseUrl))
    return Promise.reject(new Error("Invalid base url"));
  return axios
    .get(`${missionServiceBaseUrl}/geocoding/`, {
      params: { search },
    })
    .then((res) => {
      if (res.status === 200) return res.data;
      throw new Error(`Unexpected status code: ${res.status}`);
    })
    .catch((err) => {
      console.error("Error while getting geocoding data: ", err);
      return Promise.reject(err);
    });
};
